import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import StoreSVG from '../../../../../_admin/assets/media/dashboard/store.svg';
import MasterSVG from '../../../../../_admin/assets/media/dashboard/master.svg';
import SellerSVG from '../../../../../_admin/assets/media/dashboard/seller.svg';
import PlatformSVG from '../../../../../_admin/assets/media/dashboard/platform.svg';
import AdvertisementSVG from '../../../../../_admin/assets/media/dashboard/advertisement.svg';
import ContactSVG from '../../../../../_admin/assets/media/dashboard/contact.svg';
import CustomerSVG from '../../../../../_admin/assets/media/dashboard/customers.svg';
import ProductSVG from '../../../../../_admin/assets/media/dashboard/inventory.svg';
import CustomerServiceSVG from '../../../../../_admin/assets/media/dashboard/customer_service.svg';
import PendingCertificatesSVG from '../../../../../_admin/assets/media/dashboard/pending_certificates.svg';
import DashboardIcon from '../../../../../_admin/assets/media/dashboard/dashboard.svg';
import CustomersIcon from '../../../../../_admin/assets/media/dashboard/customers.svg';
import MyTeamIcon from '../../../../../_admin/assets/media/dashboard/my-team.svg';
import OrdersDeliverySVG from '../../../../../_admin/assets/media/dashboard/orders-delivery.svg';
import { useEffect, useState } from 'react';
import APICallService from '../../../../../api/apiCallService';
import { SIDEBAR } from '../../../../../api/apiEndPoints';
import { useAuth } from '../../../../../app/modules/auth';
import {
  AreaBasedCustomerSegmentationReport,
  AssistAdmin,
  BrandWiseSalesPerformanceReport,
  CategoryAndSubCategoryAnalysisReport,
  CommissionAnalysisReport,
  CustomerPurchaseBehaviourReport,
  Dashboard,
  FinanceManager,
  FrequentCustomerPurchasePatternAnalysisReport,
  FulfillmentManager,
  GeographicSalesInsightReport,
  InterTransferUmart,
  InventoryStatusReport,
  LoadingBayManager,
  Manager,
  PreOrderCustomerList,
  ProductSalesPerformanceReport,
  Reports,
  RevenueGenerationReport,
  ServiceMember,
} from '../../../../../utils/constants';
import InventorySVG from '../../../../../_admin/assets/media/dashboard/_inventory.svg';
import {
  AllModules,
  Sellers,
  AllSeller,
  KycVerifications,
  PendingCertificate,
  PaymentToSellers,
  SellersSalesReport,
  Customers,
  AllCustomers,
  RefundsToCustomer,
  ReceivableReports,
  OrdersDelivery,
  RoutePlan,
  Master,
  Categories,
  BusinessType,
  BusinessCertificates,
  Brand,
  TechnicalDetails,
  ProductVariants,
  MasterProduct,
  Driver,
  Vehicle,
  VehicleLoadingArea,
  SellerProductZone,
  FinanceMember,
  RolesAndPermission,
  UserManagement,
  Inventory,
  GoodsWarehouse,
  LowStockLists,
  GoodsRequest,
  ReceivedDirectGood,
  CustomerServices,
  All_Products,
  PlatformFees,
  Advertising,
  ContactInquires,
  CustomNotifications,
  Settings,
  Privacy_Policy,
  TermsConditions,
  AppSettings,
  FinanceReports,
  OutWardReports,
  ReturnProductReports,
  DeliveryReports,
} from '../../../../../utils/constants';
import Method from '../../../../../utils/methods';
// const SidebarMenuMain = () => {
//   const { currentUser } = useAuth();
//   const [count, setCount] = useState({
//     pendingKYC: 0,
//     totalSellers: 0,
//     pendingProducts: 0,
//   });
//   useEffect(() => {
//     (async () => {
//       if (currentUser?.userType === AssistAdmin) {
//       } else {
//         await fetchCount();
//       }
//     })();
//   }, []);
//   const fetchCount = async () => {
//     let apiService = new APICallService(SIDEBAR);
//     let response = await apiService.callAPI();
//     if (response) {
//       setCount(response);
//     }
//   };
//   const renderCommonItems = () => (
//     <>
//       {/* <SidebarMenuItem
//         to="/dashboard"
//         icon={DashboardIcon}
//         title={'Dashboard'}
//         fontIcon="bi-app-indicator"
//       /> */}
//       <SidebarMenuItem
//         to="/customers/view-customers"
//         icon={CustomersIcon}
//         title={'Customers'}
//         fontIcon="bi-app-indicator"
//       />
//       {currentUser?.role.some((obj: any) => obj.type === Manager) && (
//         <SidebarMenuItem
//           to="/my-team"
//           icon={MyTeamIcon}
//           title={'My team'}
//           fontIcon="bi-app-indicator"
//         />
//       )}
//       <SidebarMenuItem
//         to="/orders"
//         title="Orders & delivery"
//         icon={OrdersDelivery}
//         fontIcon="bi-person"
//       />
//     </>
//   );
//   return (
//     <>
//       {currentUser?.userType === AssistAdmin ? (
//         <>
//           {currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
//             <>
//               <SidebarMenuItem
//                 to="/dashboard"
//                 icon={DashboardIcon}
//                 title={'Dashboard'}
//                 fontIcon="bi-app-indicator"
//               />
//               {/* <SidebarMenuItemWithSub
//                 to="/payments"
//                 title="Payments"
//                 icon={PaymentsSVG}
//                 fontIcon="bi-person"
//               >
//                 <SidebarMenuItem
//                   to="/payments/payment-dashboard"
//                   title="Dashboard"
//                   hasBullet={false}
//                 />
//                 <SidebarMenuItem
//                   to="/payments/payments-to-sellers"
//                   title="Payments to sellers"
//                   hasBullet={false}
//                 />
//                 <SidebarMenuItem
//                   to="/payments/refunds-to-customers"
//                   title="Refunds to customers"
//                   hasBullet={false}
//                 />
//               </SidebarMenuItemWithSub> */}
//               <SidebarMenuItemWithSub
//                 to="/sellers/"
//                 title="Seller"
//                 icon={StoreSVG}
//                 fontIcon="bi-person"
//               >
//                 <SidebarMenuItem
//                   to="/sellers/list-seller"
//                   title="All sellers"
//                   hasCount={count.totalSellers > 0}
//                   counter={count.totalSellers}
//                 />
//               </SidebarMenuItemWithSub>
//               <SidebarMenuItem
//                 to="/customers/view-customers"
//                 icon={CustomersIcon}
//                 title={'Customers'}
//                 fontIcon="bi-app-indicator"
//               />
//               <SidebarMenuItem
//                 to="/orders"
//                 title="Orders & delivery"
//                 icon={OrdersDelivery}
//                 fontIcon="bi-person"
//               />
//               {/* <SidebarMenuItem
//                 to="/orders"
//                 title="Orders & delivery"
//                 icon={OrdersDelivery}
//                 fontIcon="bi-person"
//               /> */}
//               <SidebarMenuItem
//                 to="/all-products"
//                 title="All products"
//                 icon={ProductSVG}
//                 fontIcon="bi-person"
//                 hasCount={count.pendingProducts > 0}
//                 counter={count.pendingProducts}
//               />
//               <SidebarMenuItemWithSub
//                 to="/inventory/"
//                 title="Inventory"
//                 icon={InventorySVG}
//                 fontIcon="bi-person"
//               >
//                 <SidebarMenuItem
//                   to="/inventory/goods-in-warehouse"
//                   title="Goods in warehouse"
//                   hasBullet={false}
//                 />
//                 <SidebarMenuItem
//                   to="/inventory/low-stock-list"
//                   title="Low stock list"
//                   hasBullet={false}
//                 />
//                 <SidebarMenuItem
//                   to="/inventory/goods-requests"
//                   title="Goods requests"
//                   hasBullet={false}
//                 />
//                 {/* <SidebarMenuItem
//               to="/inventory/return-goods"
//               title="Return goods"
//               hasBullet={false}
//             /> */}
//               </SidebarMenuItemWithSub>
//               <SidebarMenuItem
//                 to="/advertisement/view-advertisement"
//                 title="Advertising"
//                 icon={AdvertisementSVG}
//                 fontIcon="bi-person"
//               />
//               <SidebarMenuItem
//                 to="/platform-fees/fees"
//                 icon={PlatformSVG}
//                 title="Platform fees"
//                 fontIcon="bi-app-indicator"
//               />
//             </>
//           ) : (
//             <>
//               {currentUser?.role.some(
//                 (obj: any) => obj.type === LoadingBayManager
//               ) ? (
//                 <>
//                   <SidebarMenuItem
//                     to="/orders"
//                     title="Orders & delivery"
//                     icon={OrdersDelivery}
//                     fontIcon="bi-person"
//                   />
//                   {/* <SidebarMenuItem
//                     to="/all-products"
//                     title="All products"
//                     icon={ProductSVG}
//                     fontIcon="bi-person"
//                     hasCount={count.pendingProducts > 0}
//                     counter={count.pendingProducts}
//                   /> */}
//                   <SidebarMenuItemWithSub
//                     to="/inventory/"
//                     title="Inventory"
//                     icon={InventorySVG}
//                     fontIcon="bi-person"
//                   >
//                     <SidebarMenuItem
//                       to="/inventory/goods-in-warehouse"
//                       title="Goods in warehouse"
//                       hasBullet={false}
//                     />
//                     <SidebarMenuItem
//                       to="/inventory/low-stock-list"
//                       title="Low stock list"
//                       hasBullet={false}
//                     />
//                     <SidebarMenuItem
//                       to="/inventory/goods-requests"
//                       title="Goods requests"
//                       hasBullet={false}
//                     />
//                   </SidebarMenuItemWithSub>
//                 </>
//               ) : (
//                 <>
//                   {currentUser?.role.some(
//                     (obj: any) => obj.type === FulfillmentManager
//                   ) ? (
//                     <>
//                       <SidebarMenuItemWithSub
//                         to="/sellers/"
//                         title="Seller"
//                         icon={StoreSVG}
//                         fontIcon="bi-person"
//                       >
//                         <SidebarMenuItem
//                           to="/sellers/list-seller"
//                           title="All sellers"
//                           hasCount={count.totalSellers > 0}
//                           counter={count.totalSellers}
//                         />
//                       </SidebarMenuItemWithSub>
//                       <SidebarMenuItem
//                         to="/customers/view-customers"
//                         icon={CustomersIcon}
//                         title={'Customers'}
//                         fontIcon="bi-app-indicator"
//                       />
//                       {/* <SidebarMenuItem
//                         to="/orders"
//                         title="Orders & delivery"
//                         icon={OrdersDelivery}
//                         fontIcon="bi-person"
//                       /> */}
//                       <SidebarMenuItem
//                         to="/orders"
//                         title="Orders & delivery"
//                         icon={OrdersDelivery}
//                         fontIcon="bi-person"
//                       />
//                       <SidebarMenuItemWithSub
//                         to="/master/drivers"
//                         title="Master"
//                         icon={MasterSVG}
//                         fontIcon="bi-person"
//                       >
//                         <SidebarMenuItem
//                           to="/master/drivers"
//                           title="Drivers"
//                           hasBullet={false}
//                         />
//                         <SidebarMenuItem
//                           to="/master/vehicles"
//                           title="Vehicles"
//                           hasBullet={false}
//                         />
//                         <SidebarMenuItem
//                           to="/master/seller-product-zone"
//                           title="Seller products zone"
//                           hasBullet={false}
//                         />
//                         {/* <SidebarMenuItem
//           to="/error/maintanance"
//           title="Master products"
//           hasBullet={false}
//         /> */}
//                       </SidebarMenuItemWithSub>
//                       <SidebarMenuItemWithSub
//                         to="/inventory/"
//                         title="Inventory"
//                         icon={InventorySVG}
//                         fontIcon="bi-person"
//                       >
//                         <SidebarMenuItem
//                           to="/inventory/goods-in-warehouse"
//                           title="Goods in warehouse"
//                           hasBullet={false}
//                         />
//                         <SidebarMenuItem
//                           to="/inventory/low-stock-list"
//                           title="Low stock list"
//                           hasBullet={false}
//                         />
//                         <SidebarMenuItem
//                           to="/inventory/goods-requests"
//                           title="Goods requests"
//                           hasBullet={false}
//                         />
//                         {/* <SidebarMenuItem
//               to="/inventory/return-goods"
//               title="Return goods"
//               hasBullet={false}
//             /> */}
//                       </SidebarMenuItemWithSub>
//                     </>
//                   ) : (
//                     renderCommonItems()
//                   )}
//                 </>
//               )}
//             </>
//           )}
//         </>
//       ) : (
//         <>
//           {/* <SidebarMenuItem
//             to="/list-seller"
//             icon={StoreSVG}
//             title="Seller"
//             fontIcon="bi-app-indicator"
//             hasCount={count.totalSellers > 0}
//             counter={count.totalSellers}
//           /> */}
//           <SidebarMenuItem
//             to="/dashboard"
//             icon={DashboardIcon}
//             title={'Dashboard'}
//             fontIcon="bi-app-indicator"
//           />
//           <SidebarMenuItemWithSub
//             to="/sellers/"
//             title="Seller"
//             icon={StoreSVG}
//             fontIcon="bi-person"
//           >
//             <SidebarMenuItem
//               to="/sellers/list-seller"
//               title="All sellers"
//               hasCount={count.totalSellers > 0}
//               counter={count.totalSellers}
//             />
//             <SidebarMenuItem
//               to="/sellers/kyc-verification"
//               title="KYC verification"
//               hasCount={count.pendingKYC > 0}
//               counter={count.pendingKYC}
//             ></SidebarMenuItem>
//             {/* Pending certificates */}
//             <SidebarMenuItem
//               to="/sellers/pending-certificates"
//               title="Pending certificates"
//             />
//             {/* Payment to sellers */}
//             <SidebarMenuItem
//               to="/sellers/payments-to-sellers"
//               title="Payments to sellers"
//             />
//             {/* Sales Report */}
//             <SidebarMenuItem
//               to="/sellers/sales-report"
//               title="Sales Report"
//             />
//           </SidebarMenuItemWithSub>
//           <SidebarMenuItemWithSub
//             to="/customers"
//             title="Customers"
//             icon={MasterSVG}
//             fontIcon="bi-person"
//           >
//             <SidebarMenuItem
//               to="/customers"
//               title="All Customers"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/customers/refunds-to-customers"
//               title="Refunds to Customers"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/customers/receivable-report"
//               title="Receivable Report"
//               hasBullet={false}
//             />
//           </SidebarMenuItemWithSub>
//           <SidebarMenuItem
//             to="/orders"
//             title="Orders & delivery"
//             icon={OrdersDelivery}
//             fontIcon="bi-person"
//           />
//           <SidebarMenuItemWithSub
//             to="/master/category"
//             title="Master"
//             icon={MasterSVG}
//             fontIcon="bi-person"
//           >
//             <SidebarMenuItem
//               to="/master/category"
//               title="Categories"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/business-types"
//               title="Business types"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/business-certificates"
//               title="Business certificates"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/brands"
//               title="Brands"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/technical-details"
//               title="Technical details"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/product-variants"
//               title="Product variants"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/products/master-products"
//               title="Master products"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/drivers"
//               title="Drivers"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/vehicles"
//               title="Vehicles"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/loading-areas"
//               title="Vehicle loading areas"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/seller-product-zone"
//               title="Seller products zone"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/finance-members"
//               title="Finance members"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/master/roles-permissions"
//               title="Roles & Permission"
//               hasBullet={false}
//             />
//             {/* <SidebarMenuItem
//           to="/error/maintanance"
//           title="Master products"
//           hasBullet={false}
//         /> */}
//           </SidebarMenuItemWithSub>
//           <SidebarMenuItemWithSub
//             to="/inventory/"
//             title="Inventory"
//             icon={InventorySVG}
//             fontIcon="bi-person"
//           >
//             <SidebarMenuItem
//               to="/inventory/goods-in-warehouse"
//               title="Goods in warehouse"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/inventory/low-stock-list"
//               title="Low stock list"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/inventory/goods-requests"
//               title="Goods requests"
//               hasBullet={false}
//             />
//             {/* <SidebarMenuItem
//               to="/inventory/return-goods"
//               title="Return goods"
//               hasBullet={false}
//             /> */}
//             <SidebarMenuItem
//               to="/inventory/received-direct-goods"
//               title="Received direct goods"
//               hasBullet={false}
//             />
//           </SidebarMenuItemWithSub>
//           <SidebarMenuItem
//             to="/customer-service"
//             title="Customer service"
//             icon={CustomerServiceSVG}
//             fontIcon="bi-person"
//           />
//           <SidebarMenuItem
//             to="/all-products"
//             title="All products"
//             icon={ProductSVG}
//             fontIcon="bi-person"
//             hasCount={count.pendingProducts > 0}
//             counter={count.pendingProducts}
//           />
//           <SidebarMenuItem
//             to="/platform-fees/fees"
//             icon={PlatformSVG}
//             title="Platform fees"
//             fontIcon="bi-app-indicator"
//           />
//           <SidebarMenuItem
//             to="/advertisement/view-advertisement"
//             title="Advertising"
//             icon={AdvertisementSVG}
//             fontIcon="bi-person"
//           />
//           <SidebarMenuItem
//             to="/contact-inquiries/inquiries"
//             icon={ContactSVG}
//             title="Contact inquiries"
//             fontIcon="bi-app-indicator"
//           />
//           <SidebarMenuItem
//             to="/custom-notification"
//             title="Custom Notification"
//             icon={AdvertisementSVG}
//             fontIcon="bi-person"
//           />
//           <SidebarMenuItemWithSub
//             to="/settings/"
//             title="Settings"
//             icon={MasterSVG}
//             fontIcon="bi-person"
//           >
//             <SidebarMenuItem
//               to="/settings/privacy-policy"
//               title="Privacy Policy"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/settings/terms-condition"
//               title="Terms & Conditions"
//               hasBullet={false}
//             />
//             <SidebarMenuItem
//               to="/settings/app-settings"
//               title="App Settings"
//               hasBullet={false}
//             />
//           </SidebarMenuItemWithSub>
//         </>
//       )}
//     </>
//   );
// };
const SidebarMenuMain = () => {
  const { currentUser } = useAuth();
  const [count, setCount] = useState({
    pendingKYC: 0,
    totalSellers: 0,
    pendingProducts: 0,
  });
  useEffect(() => {
    (async () => {
      if (currentUser?.userType === AssistAdmin) {
      } else {
        await fetchCount();
      }
    })();
  }, []);
  const fetchCount = async () => {
    let apiService = new APICallService(SIDEBAR);
    let response = await apiService.callAPI();
    if (response) {
      setCount(response);
    }
  };
  const renderAssistAdminItems = () => {
    if (currentUser?.role.some((obj: any) => obj.type === Manager)) {
      return (
        <>
          {/* <SidebarMenuItem
        to="/dashboard"
        icon={DashboardIcon}
        title={'Dashboard'}
        fontIcon="bi-app-indicator"
        /> */}
          <SidebarMenuItem
            to="/customers/view-customers"
            icon={CustomersIcon}
            title={'Customers'}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/my-team"
            icon={MyTeamIcon}
            title={'My team'}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/orders"
            title="Orders & delivery"
            icon={OrdersDeliverySVG}
            fontIcon="bi-person"
          />
        </>
      );
    } else if (
      currentUser?.role.some((obj: any) => obj.type === FinanceManager)
    ) {
      return (
        <>
          {/* <SidebarMenuItem
          to="/dashboard"
          icon={DashboardIcon}
          title={'Dashboard'}
          fontIcon="bi-app-indicator"
        /> */}
          <SidebarMenuItemWithSub
            to="/sellers/"
            title="Seller"
            icon={StoreSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/sellers/list-seller"
              title="All sellers"
              hasCount={count.totalSellers > 0}
              counter={count.totalSellers}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/customers/view-customers"
            icon={CustomersIcon}
            title={'Customers'}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItemWithSub
            to="/finance-reports/"
            title="Finance Reports"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="finance-reports/outward-report"
              title="Outward Reports"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="finance-reports/return-product-report"
              title="Return Product Reports"
              hasBullet={false}
            />
            {/* <SidebarMenuItem
              to="/customers/refunds-to-customers"
              title="Refunds to Customers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/customers/receivable-report"
              title="Receivable Report"
              hasBullet={false}
            /> */}
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/orders"
            title="Orders & delivery"
            icon={OrdersDeliverySVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItem
            to="/all-products"
            title="All products"
            icon={ProductSVG}
            fontIcon="bi-person"
            hasCount={count.pendingProducts > 0}
            counter={count.pendingProducts}
          />
          <SidebarMenuItemWithSub
            to="/inventory/"
            title="Inventory"
            icon={InventorySVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/inventory/goods-in-warehouse"
              title="Goods in warehouse"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/low-stock-list"
              title="Low stock list"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/goods-requests"
              title="Goods requests"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/advertisement/view-advertisement"
            title="Advertising"
            icon={AdvertisementSVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItem
            to="/platform-fees/fees"
            icon={PlatformSVG}
            title="Platform fees"
            fontIcon="bi-app-indicator"
          />
        </>
      );
    } else if (
      currentUser?.role.some((obj: any) => obj.type === LoadingBayManager)
    ) {
      return (
        <>
          <SidebarMenuItem
            to="/orders"
            title="Orders & delivery"
            icon={OrdersDeliverySVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItemWithSub
            to="/inventory/"
            title="Inventory"
            icon={InventorySVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/inventory/goods-in-warehouse"
              title="Goods in warehouse"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/low-stock-list"
              title="Low stock list"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/goods-requests"
              title="Goods requests"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
        </>
      );
    } else if (
      currentUser?.role.some((obj: any) => obj.type === FulfillmentManager)
    ) {
      return (
        <>
          <SidebarMenuItemWithSub
            to="/sellers/"
            title="Seller"
            icon={StoreSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/sellers/list-seller"
              title="All sellers"
              hasCount={count.totalSellers > 0}
              counter={count.totalSellers}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/customers/view-customers"
            icon={CustomersIcon}
            title={'Customers'}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/orders"
            title="Orders & delivery"
            icon={OrdersDeliverySVG}
            fontIcon="bi-person"
          />
          <SidebarMenuItemWithSub
            to="/master/drivers"
            title="Master"
            icon={MasterSVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/master/drivers"
              title="Drivers"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/vehicles"
              title="Vehicles"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/master/seller-product-zone"
              title="Seller products zone"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/inventory/"
            title="Inventory"
            icon={InventorySVG}
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/inventory/goods-in-warehouse"
              title="Goods in warehouse"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/low-stock-list"
              title="Low stock list"
              hasBullet={false}
            />
            <SidebarMenuItem
              to="/inventory/goods-requests"
              title="Goods requests"
              hasBullet={false}
            />
          </SidebarMenuItemWithSub>
        </>
      );
    } else {
      return renderMenuItems();
    }
  };
  const renderMenuItems = () => {
    const menuItems = [];
    // Dashboard
    if (Method.hasModulePermission(Dashboard, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="dashboard"
          to="/dashboard"
          icon={DashboardIcon}
          title={'Dashboard'}
          fontIcon="bi-app-indicator"
        />
      );
    }
    if (
      Method.hasModulePermission(Reports, currentUser) ||
      Method.hasModulePermission(
        CustomerPurchaseBehaviourReport,
        currentUser
      ) ||
      Method.hasModulePermission(GeographicSalesInsightReport, currentUser) ||
      Method.hasModulePermission(
        AreaBasedCustomerSegmentationReport,
        currentUser
      ) ||
      Method.hasModulePermission(
        FrequentCustomerPurchasePatternAnalysisReport,
        currentUser
      ) ||
      Method.hasModulePermission(ProductSalesPerformanceReport, currentUser) ||
      Method.hasModulePermission(
        BrandWiseSalesPerformanceReport,
        currentUser
      ) ||
      Method.hasModulePermission(
        CategoryAndSubCategoryAnalysisReport,
        currentUser
      ) ||
      Method.hasModulePermission(RevenueGenerationReport, currentUser) ||
      Method.hasModulePermission(CommissionAnalysisReport, currentUser) ||
      Method.hasModulePermission(InventoryStatusReport, currentUser)
    ) {
      menuItems.push(
        <SidebarMenuItem
          key="reports"
          to="/reports/index"
          title="Reports"
          icon={MasterSVG}
          fontIcon="bi-person"
        />
      );
    }
    // Sellers
    const sellerSubItems: any = [];
    if (Method.hasModulePermission(AllSeller, currentUser)) {
      sellerSubItems.push(
        <SidebarMenuItem
          to="/sellers/list-seller"
          title="All sellers"
        />
      );
    }
    if (Method.hasModulePermission(KycVerifications, currentUser)) {
      sellerSubItems.push(
        <SidebarMenuItem
          to="/sellers/kyc-verification"
          title="KYC verification"
        />
      );
    }
    if (Method.hasModulePermission(PendingCertificate, currentUser)) {
      sellerSubItems.push(
        <SidebarMenuItem
          to="/sellers/pending-certificates"
          title="Pending certificates"
        />
      );
    }
    if (Method.hasModulePermission(PaymentToSellers, currentUser)) {
      sellerSubItems.push(
        <SidebarMenuItem
          to="/sellers/payments-to-sellers"
          title="Payments to sellers"
        />
      );
    }
    if (Method.hasModulePermission(SellersSalesReport, currentUser)) {
      sellerSubItems.push(
        <SidebarMenuItem
          to="/sellers/sales-report"
          title="Sales Report"
        />
      );
    }
    if (sellerSubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="sellers"
          to="/sellers/"
          title="Seller"
          icon={StoreSVG}
          fontIcon="bi-person"
        >
          {sellerSubItems}
        </SidebarMenuItemWithSub>
      );
    }
    // Customers
    const customerSubItems: any = [];
    if (Method.hasModulePermission(AllCustomers, currentUser)) {
      customerSubItems.push(
        <SidebarMenuItem
          to="/customers"
          title="All Customers"
        />
      );
    }
    if (Method.hasModulePermission(RefundsToCustomer, currentUser)) {
      customerSubItems.push(
        <SidebarMenuItem
          to="/customers/refunds-to-customers"
          title="Refunds to Customers"
        />
      );
    }
    if (Method.hasModulePermission(ReceivableReports, currentUser)) {
      customerSubItems.push(
        <SidebarMenuItem
          to="/customers/receivable-report"
          title="Receivable Report"
        />
      );
    }
     if (Method.hasModulePermission(PreOrderCustomerList, currentUser)) {
       customerSubItems.push(
         <SidebarMenuItem
           to="/customers/pre-order-customers-list"
           title="Pre order customer"
         />
       );
     }
    if (customerSubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="customers"
          to="/customers"
          title="Customers"
          icon={CustomersIcon}
          fontIcon="bi-person"
        >
          {customerSubItems}
        </SidebarMenuItemWithSub>
      );
    }
    <SidebarMenuItemWithSub
      to="/finance-reports/"
      title="Finance Reports"
      icon={MasterSVG}
      fontIcon="bi-person"
    >
      <SidebarMenuItem
        to="finance-reports/outward-report"
        title="Outward Reports"
        hasBullet={false}
      />
      <SidebarMenuItem
        to="finance-reports/return-product-report"
        title="Return Product Reports"
        hasBullet={false}
      />
      {/* <SidebarMenuItem
        to="finance-reports/delivery-report"
        title="Delivery Reports"
        hasBullet={false}
      /> */}
      <SidebarMenuItem
        to="finance-reports/return-product-report"
        title="Return Product Reports"
        hasBullet={false}
      />
    </SidebarMenuItemWithSub>;
    // Finance Reports
    const financeSubItems: any = [];
    if (Method.hasModulePermission(OutWardReports, currentUser)) {
      financeSubItems.push(
        <SidebarMenuItem
          to="/finance-reports/outward-report"
          title="Outward Reports"
          hasBullet={false}
        />
      );
    }
    if (Method.hasModulePermission(ReturnProductReports, currentUser)) {
      financeSubItems.push(
        <SidebarMenuItem
          to="/finance-reports/return-product-report"
          title="Return Product Reports"
          hasBullet={false}
        />
      );
    }
    // if (Method.hasModulePermission(DeliveryReports, currentUser)) {
    //   financeSubItems.push(
    //     <SidebarMenuItem
    //       to="/finance-reports/delivery-report"
    //       title="Delivery Reports"
    //       hasBullet={false}
    //     />
    //   );
    // }
    if (financeSubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="finance-reports"
          to="/finance-reports"
          title="Finance Reports"
          icon={MasterSVG}
          fontIcon="bi-person"
        >
          {financeSubItems}
        </SidebarMenuItemWithSub>
      );
    }
    // Orders & Delivery
    if (Method.hasModulePermission(OrdersDelivery, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="orders"
          to="/orders"
          title="Orders & delivery"
          icon={OrdersDeliverySVG}
          fontIcon="bi-person"
        />
      );
    }
    // Master
    const masterSubItems = [];
    if (Method.hasModulePermission(Categories, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="category"
          to="/master/category"
          title="Categories"
        />
      );
    }
    if (Method.hasModulePermission(BusinessType, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="business-types"
          to="/master/business-types"
          title="Business types"
        />
      );
    }
    if (Method.hasModulePermission(BusinessCertificates, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="business-certificates"
          to="/master/business-certificates"
          title="Business certificates"
        />
      );
    }
    if (Method.hasModulePermission(Brand, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="brands"
          to="/master/brands"
          title="Brands"
        />
      );
    }
    if (Method.hasModulePermission(TechnicalDetails, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="technical-details"
          to="/master/technical-details"
          title="Technical details"
        />
      );
    }
    if (Method.hasModulePermission(ProductVariants, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="product-variants"
          to="/master/product-variants"
          title="Product variants"
        />
      );
    }
    if (Method.hasModulePermission(MasterProduct, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="master-products"
          to="/products/master-products"
          title="Master products"
        />
      );
    }
    if (Method.hasModulePermission(Driver, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="drivers"
          to="/master/drivers"
          title="Drivers"
        />
      );
    }
    if (Method.hasModulePermission(Vehicle, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="vehicles"
          to="/master/vehicles"
          title="Vehicles"
        />
      );
    }
    if (Method.hasModulePermission(VehicleLoadingArea, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="loading-areas"
          to="/master/loading-areas"
          title="Vehicle loading areas"
        />
      );
    }
    if (Method.hasModulePermission(SellerProductZone, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="seller-product-zone"
          to="/master/seller-product-zone"
          title="Seller products zone"
        />
      );
    }
    if (Method.hasModulePermission(RolesAndPermission, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="roles-permissions"
          to="/master/roles-permissions"
          title="Roles & Permission"
        />
      );
    }
    if (Method.hasModulePermission(UserManagement, currentUser)) {
      masterSubItems.push(
        <SidebarMenuItem
          key="User-management"
          to="/master/user-management"
          title="User Management"
        />
      );
    }
    if (masterSubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="master"
          to="/master/category"
          title="Master"
          icon={MasterSVG}
          fontIcon="bi-person"
        >
          {masterSubItems}
        </SidebarMenuItemWithSub>
      );
    }
    // Inventory
    const inventorySubItems: any = [];
    if (Method.hasModulePermission(GoodsWarehouse, currentUser)) {
      inventorySubItems.push(
        <SidebarMenuItem
          to="/inventory/goods-in-warehouse"
          title="Goods in Warehouse"
        />
      );
    }
    if (Method.hasModulePermission(LowStockLists, currentUser)) {
      inventorySubItems.push(
        <SidebarMenuItem
          to="/inventory/low-stock-list"
          title="Low Stock List"
        />
      );
    }
    if (Method.hasModulePermission(GoodsRequest, currentUser)) {
      inventorySubItems.push(
        <SidebarMenuItem
          to="/inventory/goods-requests"
          title="Goods Requests"
        />
      );
    }
    if (Method.hasModulePermission(ReceivedDirectGood, currentUser)) {
      inventorySubItems.push(
        <SidebarMenuItem
          to="/inventory/received-direct-goods"
          title="Received Direct Goods"
        />
      );
    }
    if (inventorySubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="inventory"
          to="/inventory/"
          title="Inventory"
          icon={InventorySVG}
          fontIcon="bi-person"
        >
          {inventorySubItems}
        </SidebarMenuItemWithSub>
      );
    }
    if (Method.hasModulePermission(InterTransferUmart, currentUser)){
      menuItems.push(
        <SidebarMenuItem
          key="inter-transfer-request"
          to="/inter-transfer-request"
          icon={OrdersDeliverySVG}
          title={'Inter transfer request (UMart)'}
          fontIcon="bi-app-indicator"
        />
      );
    }
    // Customer Service
    if (Method.hasModulePermission(CustomerServices, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="customer-service"
          to="/customer-service"
          title="Customer service"
          icon={CustomerServiceSVG}
          fontIcon="bi-person"
        />
      );
    }
    // All Products
    if (Method.hasModulePermission(All_Products, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="all-products"
          to="/all-products"
          title="All products"
          icon={ProductSVG}
          fontIcon="bi-person"
        />
      );
    }
    // Platform Fees
    if (Method.hasModulePermission(PlatformFees, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="platform-fees"
          to="/platform-fees/fees"
          icon={PlatformSVG}
          title="Platform fees"
          fontIcon="bi-app-indicator"
        />
      );
    }
    // Advertising
    if (Method.hasModulePermission(Advertising, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="advertising"
          to="/advertisement/view-advertisement"
          title="Advertising"
          icon={AdvertisementSVG}
          fontIcon="bi-person"
        />
      );
    }
    // Contact Inquiries
    if (Method.hasModulePermission(ContactInquires, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="contact-inquiries"
          to="/contact-inquiries/inquiries"
          icon={ContactSVG}
          title="Contact inquiries"
          fontIcon="bi-app-indicator"
        />
      );
    }
    // Custom Notification
    if (Method.hasModulePermission(CustomNotifications, currentUser)) {
      menuItems.push(
        <SidebarMenuItem
          key="custom-notification"
          to="/custom-notification"
          title="Custom Notification"
          icon={AdvertisementSVG}
          fontIcon="bi-person"
        />
      );
    }
    // Settings
    const settingsSubItems: any = [];
    if (Method.hasModulePermission(Privacy_Policy, currentUser)) {
      settingsSubItems.push(
        <SidebarMenuItem
          to="/settings/privacy-policy"
          title="Privacy Policy"
        />
      );
    }
    if (Method.hasModulePermission(TermsConditions, currentUser)) {
      settingsSubItems.push(
        <SidebarMenuItem
          to="/settings/terms-condition"
          title="Terms & Conditions"
        />
      );
    }
    if (Method.hasModulePermission(AppSettings, currentUser)) {
      settingsSubItems.push(
        <SidebarMenuItem
          to="/settings/app-settings"
          title="App Settings"
        />
      );
    }
    if (settingsSubItems.length > 0) {
      menuItems.push(
        <SidebarMenuItemWithSub
          key="settings"
          to="/settings/"
          title="Settings"
          icon={MasterSVG}
          fontIcon="bi-person"
        >
          {settingsSubItems}
        </SidebarMenuItemWithSub>
      );
    }
    return menuItems;
  };
  return (
    <>
      {currentUser?.userType === AssistAdmin
        ? renderAssistAdminItems()
        : renderMenuItems()}
    </>
  );
};
export { SidebarMenuMain };
