export const listSeller = {
  page: 'listSellerPage',
  limit: 'listSellerLimit',
  currentTab: 'listSellerCurrentTab',
  scrollPosition: 'listSellerScrollPosition',
  search: 'listSellerSearch',
};
export const sellerProfile = {
  currentTab: 'sellerProfileCurrentTab',
};
export const listSellerProfileCatalogue = {
  page: 'listSellerProfileCataloguePage',
  limit: 'listSellerProfileCatalogueLimit',
  search: 'listSellerProfileCatalogueSearch',
  filterCategories: 'listSellerProfileCatalogueFilterCategories'
};
export const listSellerInventory = {
  page: 'listSellerInventoryPage',
  limit: 'listSellerInventoryLimit',
  search: 'listSellerInventorySearch',
  filterCategories: 'listSellerInventoryFilterCategories',
  filterStatus: 'listSellerInventoryFilterStatus',
  filterExpiry: 'listSellerInventoryFilterExpiry'
};
export const listSellerGoodsRequest = {
  page: 'listSellerGoodsRequestPage',
  limit: 'listSellerGoodsRequestLimit',
  filterStatus: 'listSellerGoodsRequestFilterStatus',
  filterStartDate: 'listSellerGoodsRequestFilterStartDate',
  filterEndDate: 'listSellerGoodsRequestFilterEndDate',
};
export const listSellerStockDetails = {
  page: 'listSellerStockDetailsPage',
  limit: 'listSellerStockDetailsLimit',
  search: 'listSellerStockDetailsSearch',
};
export const listSellerHistoryOfInventory = {
  page: 'listSellerHistoryOfInventoryPage',
  limit: 'listSellerHistoryOfInventoryLimit',
  search: 'listSellerHistoryOfInvntorySearch',
  filterInventoryName: 'listSellerHistoryOfInventoryFilterInventoryName',
  filterInventory: 'listSellerHistoryOfInventoryFilterInventory',
  filterStartDate: 'listSellerHistoryOfInventoryFilterStartDate',
  filterEndDate: 'listSellerHistoryOfInventoryFilterEndDate',
};
export const listSellerAdvertisement = {
  page: 'listSellerAdvertisementPage',
  limit: 'listSellerAdvertisementLimit',
  filterStatus: 'listSellerAdvertisementFilterStatus',
  filterStartDate: 'listSellerAdvertisementFilterStartDate',
  filterEndDate: 'listSellerAdvertisementFilterEndDate',
};
export const listKycVerification = {
  page: 'listKycVerificationPage',
  limit: 'listKycVerificationLimit',
  currentTab: 'listKycVerificationCurrentTab',
  scrollPosition: 'listKycVerificationScollPosition',
  search: 'listKycVerificationSearch',
};
export const listPendingCertificates = {
  page: 'listPendingCertificatesPage',
  limit: 'listPendingCertificatesLimit',
};
export const listPaymentToSeller = {
  page: 'listPaymentToSellerPage',
  limit: 'listPaymentToSellerLimit',
  scrollPosition: 'listPaymentToSellerScrollPosition',
  search: 'listPaymentToSellerSearch',
};
export const listSellerFinanceDetails = {
  page: 'listSellerFinanceDetailsPage',
  limit: 'listSellerFinanceDetailsLimit',
  currentTab: 'listSellerFinanceDetailsCurrentTab',
  scrollPosition: 'listSellerFinanceDetailsScrollPosition',
  filter: 'listSellerFinanceDetailsFilter',
};

export const listCustomerFinanceDetails = {
  page: 'listCustomerFinanceDetailsPage',
  limit: 'listCustomerFinanceDetailsLimit',
  currentTab: 'listCustomerFinanceDetailsCurrentTab',
  scrollPosition: 'listCustomerFinanceDetailsScrollPosition',
  filter: 'listCustomerFinanceDetailsFilter',
};
export const listCustomerRefundDetails = {
  page: 'listCustomerRefundDetailsPage',
  limit: 'listCustomerRefundDetailsLimit',
  currentTab: 'listCustomerRefundDetailsCurrentTab',
  filter: 'listCustomerRefundDetailsFilter',
};
export const listReceivablePayouts = {
  page: 'listReceivablePayoutsPage',
  limit: 'listReceivablePayoutsLimit',
  scrollPosition: 'listReceivablePayoutsScrollPosition',
  filterStartDate: 'listReceivablePayoutsFilterStartDate',
  filterEndDate: 'listReceivablePayoutsFilterEndDate',
  filterCustomer: 'listReceivablePayoutsFilterCustomer',
  filterCustomerData: 'listReceivablePayoutsFilterCustomerData'
};
export const listCustomers = {
  page: 'listCustomersPage',
  limit: 'listCustomersLimit',
  scrollPosition: 'listCustomersScrollPosition',
  search: 'listCustomersSearch',
  filterCustomerState: 'listCustomersFilterCustomerState'
};
export const listCustomerProfile = {
  currentTab: 'listCustomerProfileCurrentTab',
}
export const listRefundCustomers = {
  page: 'listRefundCustomersPage',
  limit: 'listRefundCustomersLimit',
  scrollPosition: 'listRefundCustomersScrollPosition',
};
export const listOrdersDelivery = {
  page: 'listOrdersDeliveryPage',
  limit: 'listOrdersDeliveryLimit',
  currentTab: 'listOrdersDeliveryCurrentTab',
  scrollPosition: 'listOrdersDeliveryScrollPosition',
  search: 'listOrdersDeliverySearch',
  filterStartDate: 'listOrdersDeliveryFilterStartDate',
  filterEndDate: 'listOrdersDeliveryFilterEndDate',
  currentDistrict: 'listOrdersDeliveryCurrentDistrict',
  currentAreas: 'listOrdersDeliveryCurrentAreas',
  currentPlanStatus: 'listOrdersDeliveryCurrentPlanStatus',
  selectedOrders: 'selectedOrders',
  selectedVehicle: 'selectedVehicle',
  selectedListVehicle: 'selectedListVehicle',
};
export const listBuyerOrder = {
  page: 'listBuyerOrderPage',
  limit: 'listBuyerOrderLimit',
  search: 'listBuyerOrderSearch',
  currentTab: 'listBuyerOrderCurrentTab',
  currentDistrict: 'listBuyerOrderCurrentDistrict',
  currentPlanStatus: 'listBuyerOrderCurrentPlanStatus',
  filterStartDate: 'listBuyerOrderFilterStartDate',
  filterEndDate: 'listBuyerOrderFilterEndDate',
};
export const listCategories = {
  page: 'listCategoriesPage',
  limit: 'listCategoriesLimit',
  scrollPosition: 'listCategoriesScrollPosition',
};
export const listBusinessTypes = {
  page: 'listBusinessTypesPage',
  limit: 'listBusinessTypesLimit',
  scrollPosition: 'listBusinessTypesScrollPosition',
};
export const listCertificates = {
  page: 'listCertificatesPage',
  limit: 'listCertificatesLimit',
  scrollPosition: 'listCertificatesScrollPosition',
};
export const listBrands = {
  page: 'listBrandsPage',
  limit: 'listBrandsLimit',
  scrollPosition: 'listScrollPosition',
};
export const listVariants = {
  page: 'listVariantsPage',
  limit: 'listVariantsLimit',
  currentTab: 'listVariantsCurrentTab',
  currentTabId: 'listVariantsCurrentTabId',
  scrollPosition: 'listVariantsScrollPosition',
};
export const listTechnicalDetails = {
  page: 'listTechnicalDetailsPage',
  limit: 'listTechnicalDetailsLimit',
  currentTab: 'listTechnicalDetailsCurrentTab',
  currentTabId: 'listTechnicalDetailsCurrentTabId',
};
export const listVehicles = {
  page: 'listVehiclesPage',
  limit: 'listVehiclesLimit',
  scrollPosition: 'listVehiclesScrollPosition',
};
export const listDrivers = {
  page: 'listDriversPage',
  limit: 'listDriversLimit',
  scrollPosition: 'listDriversScrollPosition',
  filterByStatus: 'listDriverStatusFilter',
  filterByType: 'listDriverTypeFilter',
  searchTerm: 'ListDriverSearch'
};
export const listMasterProducts = {
  page: 'listMasterProductsPage',
  limit: 'listMasterProductsLimit',
  scrollPosition: 'listMasterProductsScrollPosition',
  search: 'listMasterProductsSearch',
  filterProductState: 'listMasterProductsFilterProductState',
  filterCategories: 'listMasterProductsFilterCategories',
};
export const listMasterProductDetails = {
  currentTab: 'listMasterProductDetailsCurrentTab'
}
export const listVehiclesLoadingArea = {
  page: 'listVehiclesLoadingAreaPage',
  limit: 'listVehiclesLoadingAreaLimit',
  scrollPosition: 'listVehiclesLoadingAreaScrollPosition',
};
export const listSellerProductZone = {
  page: 'listSellerProductZonePage',
  limit: 'listSellerProductZoneLimit',
};
export const listFinanceMember = {
  page: 'listFinanceMemberPage',
  limit: 'listFinanceMemberLimit',
  scrollPosition: 'listFinanceMemberScrollPosition',
};
export const listGoodsInWarehouse = {
  page: 'listGoodsInWarehousePage',
  limit: 'listGoodsInWarehouseLimit',
  scrollPosition: 'listGoodsInWarehouseScrollPosition',
  search: 'listGoodsInWarehouseSearch',
  filterSeller: 'listGoodsInWarehouseFilterSeller',
  filterSellerData: 'listGoodsInWarehouseFilterSellerData',
  filterProductState: 'listGoodsInWarehouseFilterProductState',
  filterExpiryPeriod: 'listGoodsInWarehouseFilterExpiryPeriod',
  filterBylastDays: 'listGoodsInWarehouseFilterByLastDays',
  removedVariant: 'listGoodsInWarehouseRemovedVariant',
};
export const listGoodsRequest = {
  page: 'listGoodsRequestPage',
  limit: 'listGoodsRequestLimit',
  currentTab: 'listGoodsRequestCurrentTab',
  scrollPosition: 'listGoodsRequestScrollPosition',
  search: 'listGoodsRequestSearch',
  filterStartDate: 'listGoodsRequestFilterStartDate',
  filterEndDate: 'listGoodsRequestFilterEndDate',
};
export const listLowStock = {
  page: 'listLowStockPage',
  limit: 'listLowStockLimit',
}
export const listDistricts = {
  page: 'listDistrictsPage',
  limit: 'listDistrictsLimit',
};
export const listCustomerServiceTeam = {
  page: 'listCustomerServiceTeamPage',
  limit: 'listCustomerServiceTeamLimit',
  search: 'listCustomerServiceTeamSearch',
  currentTab: 'listCustomerServiceTeamCurrentTab',
  scrollPosition: 'listCustmoreServiceTeamScrollPosition',
};
export const listAllProducts = {
  page: 'listAllProductsPage',
  limit: 'listAllProductsLimit',
  currentTab: 'listAllProductsCurrentTab',
  scrollPosition: 'listAllProductsScrollPosition',
  search: 'listAllProductsSearch',
  filterState: 'listAllProductsFilterState',
  filterCategories: 'listProductsFilterCategories',
};
export const listProductDetails = {
  filterInventoryName: 'listProductDetailsFilterInventoryName',
  filterInventory: 'listProductDetailsFilterInventory',
  currentTab: 'listProductDetailsCurrentTab',
  filterStartDate: 'listOrdersDeliveryFilterStartDate',
  filterEndDate: 'listOrdersDeliveryFilterEndDate'
}
export const listPlatformFees = {
  currentTab: 'listPlatformFeesCurrentTab',
};
export const listAdvertisement = {
  page: 'listAdvertisementPage',
  limit: 'listAdvertisementLimit',
  currentTab: 'listAdvertisementCurrentTab',
  scrollPosition: 'listAdvertisementScrollPosition',
  search: 'listAdvertisementSearch',
};
export const listContactInquiries = {
  page: 'listContactInquiriesPage',
  limit: 'listContactInquiriesLimit',
  currentTab: 'listContactInquiriesCurrentTab',
};
export const listPreOrders = {
  page: 'listPreOrdersPage',
  limit: 'listPreOrdersLimit',
};

export const listOrdersDeliveryReport = {
  page: 'listOrdersDeliveryReportPage',
  limit: 'listOrdersDeliveryReportLimit',
  scrollPosition: 'listOrderDeliveryReportScrollPosition',
  filterPayment: 'listOrdersDeliveryReportFilterPayment',
  filterStartDate: 'listOrdersDeliveryReportFilterStartDate',
  filterEndDate: 'listOrdersFDeliveryReportFilterEndDate',
  currentDistrict: 'listOrdersDeliveryReportCurrentDistrict',
  currentAreas: 'listOrdersDeliveryReportCurrentAreas',
  currentPlanStatus: 'listOrdersDeliveryReportCurrentPlanStatus',
}
export const interTransferList = {
  page: 'listInterTransferPage',
  limit: 'listInterTransferLimit',
  scrollPosition: 'listInterTransferScrollPosition',
  filterStartDate: 'listInterTransferFilterStartDate',
  filterEndDate: 'listInterTransferFilterEndDate',
  filter: 'listInterTransferDetailsFilter'
};
