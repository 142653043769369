export const interTransferJSON = {
  addTransferRequest: ({
    variants,
    discounts,
    warehouseRequest,
  }: any) => {
    let temp: any = [];
    variants.map((val: any) => {
      temp.push({
        variant: val._id,
        quantityType: val.quantityTypes[0].type,
        stockCount: val.quantityTypes[0].stockCount,
      });
    });
    let returnData: any = {
      variants: temp,
      discounts: discounts,
      warehouseRequest: warehouseRequest
    };
    return returnData;
  },
};