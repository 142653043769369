import React, { useEffect, useState } from 'react';
import { Card, Col, FormLabel, Row } from 'react-bootstrap';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import {
  expiryJSON,
  productStatusJSON,
  stockJSON,
} from '../../../utils/staticJSON';
import Pagination from '../../../Global/pagination';
import { listPreOrders } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import {
  ApprovedProduct,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  PAGE_LIMIT,
  PendingProduct,
  Piece,
  PreOrderCustomerList,
} from '../../../utils/constants';
import {
  buyer,
  manageProduct,
  preOrderCustomer,
  preOrders,
  seller,
} from '../../../api/apiEndPoints';
import APICallService from '../../../api/apiCallService';
import Loader from '../../../Global/loader';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
const PreOrderCustomers = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [fetchLoading,setFetchLoading] = useState<any>(false)
  const [preOrdersData, setPreOrdersData] = useState([]);
  const [page, setPage] = useState<any>(getKey(listPreOrders.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listPreOrders.limit) || PAGE_LIMIT
  );
  const [sellerData, setSellerData] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState<any>(null);
  const [totalSellers, setTotalSellers] = useState(0);
  const [productData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productPage,setProductPage] = useState(1)
  const [productPageLimit,setProductPageLimit] = useState(10)
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [customerPage,setCustomerPage] = useState(1)
  const [customerPageLimit,setCustomerPageLimit] = useState(10)
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(PreOrderCustomerList, currentUser)) {
        return window.history.back();
      }
      await fetchPreOrders(page, pageLimit);
      await fetchSeller();
      await fetchProducts(productPage,productPageLimit);
      await fetchCustomers(customerPage,customerPageLimit);
    })();
  }, []);
  const fetchPreOrders = async (
    pageNo: number,
    limit: number,
    seller?: any,
    variants?: any,
    buyers?: any
  ) => {
    setLoading(true);
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: 1,
      needCount: true,
    };
    if (seller) {
      params = { ...params, 'business[0]': seller.value };
    }
    if (variants) {
      params = { ...params, 'variants[0]': variants.value };
    }
    if (buyers) {
      params = { ...params, 'buyers[0]': buyers.value };
    }
    let apiService = new APICallService(preOrders.list, params,'','','','',PreOrderCustomerList);
    let response = await apiService.callAPI();
    if (response.total) {
      setTotalRecords(response.total);
    } 
    // else {
    //   let prevTotal = totalRecords;
    //   setTotalRecords(prevTotal);
    // }
    setPreOrdersData(response.records);
    setLoading(false);
  };
  const fetchSeller = async () => {
    let params = {
      pageNo: 1,
      limit: 0,
      sortKey: 'name',
      sortOrder: 1,
      ['status[0]']: 4,
      needCount: true,
    };
    setFetchLoading(true)
    let apiService = new APICallService(preOrderCustomer.listBusiness, params,'','','','',PreOrderCustomerList);
    let response = await apiService.callAPI();
    setTotalSellers(response.total);
    setSellerData(response.records);
    setFetchLoading(false)
  };
  const fetchProducts = async ( page: number,
    limit: number,
    search: string = '') => {
    setFetchLoading(true);
    let data = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      ['status[0]']: ApprovedProduct,
      ['status[1]']: PendingProduct,
      needCount: true,
    };
    let apiService = new APICallService(preOrderCustomer.listProducts, data,'','','','',PreOrderCustomerList);
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalProducts(response.total);
      let data: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setProductData(data);
    } else {
      setProductData([]);
    }
    setFetchLoading(false);
  };
  const fetchCustomers = async (page: number,
    limit: number,
    search: string = '') => {
    setFetchLoading(true);
    let data = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      state: 1,
      needCount: true,
    };
    let apiService = new APICallService(preOrderCustomer.listBuyers, data,'','','','',PreOrderCustomerList);
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalCustomer(response.total);
      let data: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setCustomerData(data);
    } else {
      setCustomerData([]);
    }
    setFetchLoading(false);
  };
  const handleSeller = async (event: any) => {
    setLoading(true);
    setPage(1);
    setKey(listPreOrders.page, 1);
    setTotalRecords(0);
    // setProducts([]);
    if (event) {
      setSelectedSeller(event);
      await fetchPreOrders(
        1,
        pageLimit,
        event,
        selectedProduct,
        selectedCustomer
      );
    } else {
      setSelectedSeller('');
      await fetchPreOrders(1, pageLimit, '', selectedProduct, selectedCustomer);
    }
    setLoading(false);
  };
  const handleProduct = async (event: any) => {
    setLoading(true);
    setPage(1);
    setKey(listPreOrders.page, 1);
    setTotalRecords(0);
    // setProducts([]);
    if (event) {
      setSelectedProduct(event);
      await fetchPreOrders(
        1,
        pageLimit,
        selectedSeller,
        event,
        selectedCustomer
      );
    } else {
      setSelectedProduct('');
      await fetchPreOrders(1, pageLimit, selectedSeller, '', selectedCustomer);
    }
    setLoading(false);
  };
  const handleCustomer = async (event: any) => {
    setLoading(true);
    setPage(1);
    setKey(listPreOrders.page, 1);
    setTotalRecords(0);
    // setProducts([]);
    if (event) {
      setSelectedCustomer(event);
      await fetchPreOrders(
        1,
        pageLimit,
        selectedSeller,
        selectedProduct,
        event
      );
    } else {
      setSelectedCustomer('');
      await fetchPreOrders(1, pageLimit, selectedSeller, selectedProduct, '');
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listPreOrders.page, val);
    await fetchPreOrders(
      val,
      pageLimit,
      selectedSeller,
      selectedProduct,
      selectedCustomer
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listPreOrders.page, val + 1);
    await fetchPreOrders(
      val + 1,
      pageLimit,
      selectedSeller,
      selectedProduct,
      selectedCustomer
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listPreOrders.page, val - 1);
    await fetchPreOrders(
      val - 1,
      pageLimit,
      selectedSeller,
      selectedProduct,
      selectedCustomer
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    setKey(listPreOrders.page, 1);
    setKey(listPreOrders.limit, parseInt(event.target.value));
    await fetchPreOrders(
      1,
      event.target.value,
      selectedSeller,
      selectedProduct,
      selectedCustomer
    );
  };
  const onProductMenuScrollToBottom = async () => {
    if (!(productData.length === totalProducts)) {
      let tempPage = productPage;
      tempPage = tempPage + 1;
      setProductPage(tempPage);
      await fetchProducts(tempPage, productPageLimit, productSearchTerm);
    }
  };
  const onProductMenuClose = async () => {
    setProductSearchTerm('');
    setProductPage(1)
  };
  const onProductMenuOpen = async () => {
    setProductPage(1);
    await fetchProducts(1, productPageLimit, productSearchTerm);
  };
  const handleProductInputChange = async (newValue: string) => {
    setProductPage(1);
    setProductSearchTerm(newValue);
    await fetchProducts(1, productPageLimit, newValue);
  };
  const onCustomerMenuScrollToBottom = async () => {
    if (!(customerData.length === totalCustomer)) {
      let tempPage = customerPage;
      tempPage = tempPage + 1;
      setCustomerPage(tempPage);
      await fetchCustomers(tempPage, customerPageLimit, customerSearchTerm);
    }
  };
  const onCustomerMenuClose = async () => {
    setCustomerSearchTerm('');
    setCustomerPage(1)
  };
  const onCustomerMenuOpen = async () => {
    setCustomerPage(1);
    await fetchCustomers(1, customerPageLimit, customerSearchTerm);
  };
  const handleCustomerInputChange = async (newValue: string) => {
    setCustomerPage(1);
    setCustomerSearchTerm(newValue);
    await fetchCustomers(1, customerPageLimit, newValue);
  };
  console.log('totalRecords', totalRecords)
  return (
    <div className="p-9">
      <Row className="align-items-center">
        <Col
          xs={12}
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">Pre-order customer list</h1>
        </Col>
        <Col xs={12}>
          <Card className="bg-light border mb-7">
            <Card.Body className="px-7">
              <Row className="align-items-center g-5">
                <Col
                  md={6}
                  lg={4}
                >
                  <FormLabel className="fs-16 fw-500 text-dark">
                    Filter by customers
                  </FormLabel>
                  <CustomSelect
                   isLoading = {fetchLoading}
                    backgroundColor="white"
                    default={[
                      {
                        value: 'all_customers',
                        name: 'all_customers',
                        label: (
                          <>
                            <span className="fs-16 fw-600 text-black mb-0">
                              All customers{' '}
                              {totalCustomer ? ' (' + totalCustomer + ')' : ''}
                            </span>
                          </>
                        ),
                        title: 'All customers',
                      },
                    ]}
                    value={[
                      selectedCustomer
                        ? selectedCustomer
                        : {
                            value: 'all_customers',
                            name: 'all_customers',
                            label: (
                              <>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  All customers{' '}
                                  {totalCustomer
                                    ? ' (' + totalCustomer + ')'
                                    : ''}
                                </span>
                              </>
                            ),
                            title: 'All customers',
                          },
                    ]}
                    options={
                      customerData && customerData.length
                        ? customerData.map((val: any) => {
                            return {
                              label: (
                                <>
                                  <div className="symbol symbol-30px border me-3">
                                    <img
                                      src={val.business.image}
                                      className="object-fit-contain"
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.business.name}
                                  </span>
                                </>
                              ),
                              value: val._id,
                              title: val.business.name,
                            };
                          })
                        : []
                    }
                    onChange={(event: any) => {
                      handleCustomer(event);
                    }}
                    onMenuClose={() => {
                      onCustomerMenuClose();
                    }}
                    onMenuOpen={() => {
                      onCustomerMenuOpen();
                    }}
                    onMenuScrollToBottom={onCustomerMenuScrollToBottom}
                    onInputChange={(newValue: any, { action }: any) => {
                      if (action === 'input-change') {
                        handleCustomerInputChange(newValue);
                      }
                    }}
                    isClearable={selectedCustomer ? true : false}
                  />
                </Col>
                <Col
                  md={6}
                  lg={4}
                >
                  <FormLabel className="fs-16 fw-500 text-dark">
                    Filter by seller
                  </FormLabel>
                  <CustomSelect
                   isLoading = {fetchLoading}
                    backgroundColor="white"
                    default={[
                      {
                        value: 'all_sellers',
                        name: 'all_sellers',
                        label: (
                          <>
                            <span className="fs-16 fw-600 text-black mb-0">
                              All sellers{' '}
                              {totalSellers ? ' (' + totalSellers + ')' : ''}
                            </span>
                          </>
                        ),
                        title: 'All sellers',
                      },
                    ]}
                    value={[
                      selectedSeller
                        ? selectedSeller
                        : {
                            value: 'all_sellers',
                            name: 'all_sellers',
                            label: (
                              <>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  All sellers{' '}
                                  {totalSellers
                                    ? ' (' + totalSellers + ')'
                                    : ''}
                                </span>
                              </>
                            ),
                            title: 'All sellers',
                          },
                    ]}
                    options={
                      sellerData && sellerData.length
                        ? sellerData.map((val: any) => {
                            return {
                              label: (
                                <>
                                  <div className="symbol symbol-30px border me-3">
                                    <img
                                      src={val.image}
                                      className="object-fit-contain"
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.name}
                                  </span>
                                </>
                              ),
                              value: val._id,
                              title: val.name,
                            };
                          })
                        : []
                    }
                    onChange={(event: any) => {
                      handleSeller(event);
                    }}
                    isClearable={selectedSeller ? true : false}
                  />
                </Col>
                <Col
                  md={6}
                  lg={4}
                >
                  <FormLabel className="fs-16 fw-500 text-dark">
                    Filter by products
                  </FormLabel>
                  <CustomSelect
                   isLoading = {fetchLoading}
                    backgroundColor="white"
                    default={[
                      {
                        value: 'all_products',
                        name: 'all_products',
                        label: (
                          <>
                            <span className="fs-16 fw-600 text-black mb-0">
                              All products{' '}
                              {totalProducts ? ' (' + totalProducts + ')' : ''}
                            </span>
                          </>
                        ),
                        title: 'All products',
                      },
                    ]}
                    value={[
                      selectedProduct
                        ? selectedProduct
                        : {
                            value: 'all_products',
                            name: 'all_products',
                            label: (
                              <>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  All products{' '}
                                  {totalProducts
                                    ? ' (' + totalProducts + ')'
                                    : ''}
                                </span>
                              </>
                            ),
                            title: 'All products',
                          },
                    ]}
                    options={
                      productData && productData.length
                        ? productData.map((val: any) => {
                            return {
                              label: (
                                <>
                                  <div className="symbol symbol-30px border me-3">
                                    <img
                                      src={val.media[0].image}
                                      className="object-fit-contain"
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.title}
                                  </span>
                                </>
                              ),
                              value: val._id,
                              title: val.title,
                            };
                          })
                        : []
                    }
                    onChange={(event: any) => {
                      handleProduct(event);
                    }}
                    onMenuClose={() => {
                      onProductMenuClose();
                    }}
                    onMenuOpen={() => {
                      onProductMenuOpen();
                    }}
                    onMenuScrollToBottom={onProductMenuScrollToBottom}
                    onInputChange={(newValue: any, { action }: any) => {
                      if (action === 'input-change') {
                        handleProductInputChange(newValue);
                      }
                    }}
                    isClearable={selectedProduct ? true : false}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="border border-r10px">
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                  <thead>
                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                      <th className="min-w-300px">Business Name</th>
                      <th className="min-w-300px">Product Name</th>
                      <th className="min-w-200px">Quantity</th>
                      <th className="min-w-200px">Added on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        {' '}
                        <tr>
                          <td colSpan={4}>
                            <div className="d-flex justify-content-center">
                              <Loader loading={loading} />
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {preOrdersData.length ? (
                          <>
                            {' '}
                            {preOrdersData.map((val: any) => (
                              <tr key={val._id}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-5">
                                      <img
                                        src={val.customer.business.image}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <div className="fs-15 fw-600">
                                      {val.customer.business.name} <br />
                                      <span className="fw-500 text-gray">
                                        {val.customer.name}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-5">
                                      <img
                                        src={val.variant.media[0].url}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <div className="fs-15 fw-600">
                                      {val.variant.title} <br />
                                      <span className="fw-500 text-gray">
                                        {val.variant.product.business.name}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="fs-15 fw-600">
                                  {val.stockCount}{' '}
                                  {val.quantityType === CartonWithDozens ||
                                  val.quantityType === CartonWithPieces
                                    ? 'cartons'
                                    : ''}
                                  {val.quantityType === Dozen ? 'dozens' : ''}
                                  {val.quantityType === Piece ? 'pieces' : ''}
                                </td>
                                <td className="fs-15 fw-600">
                                  {Method.convertDateToFormat(
                                    val._createdAt,
                                    'DD/MM/YY [-] HH:mm'
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <>
                              <tr>
                                <td colSpan={4}>
                                  <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                    No Data found
                                  </div>
                                </td>
                              </tr>
                            </>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {!loading && totalRecords > 0 ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            pageLimit={pageLimit}
          />
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};
export default PreOrderCustomers;
