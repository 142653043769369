import { Modal } from 'react-bootstrap';
import { useEffect, useRef } from 'react';
const RouteMap = (props: any) => {
  const mapRef = useRef<any>(null);
  const waypoints = props.coordinates;
  useEffect(() => {
    if (!window.google || !mapRef.current || waypoints.length < 2) return;
    const map: any = new window.google.maps.Map(mapRef.current, {
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });
    // Create bounds object to automatically fit all points
    const bounds = new window.google.maps.LatLngBounds();
    waypoints.forEach((point: any) => {
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
    });
    // Fit map to bounds
    map.fitBounds(bounds);
    map.setZoom(map.getZoom() - 1);
    // Draw a polyline covering all coordinates
    const line = new window.google.maps.Polyline({
      path: waypoints, // Use all waypoints
      geodesic: true,
      strokeColor: '#2196F3',
      strokeOpacity: 1.0,
      strokeWeight: 4,
    });
    line.setMap(map);
    // Create markers for all waypoints
    waypoints.forEach((point: any, index: number) => {
      const isStart = index === 0; // First waypoint
      const isEnd = index === waypoints.length - 1; // Last waypoint
      const marker = new window.google.maps.Marker({
        position: point,
        map: map,
        label: {
          text: isStart || isEnd ? 'W' : index.toString(), // Display index (1-based)
          color: '#FFFFFF', // Label color
          fontSize: '14px',
          fontWeight: 'bold',
        },
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: isStart || isEnd ? '#FF0000' : '#2196F3', // Red for start/end, blue for others
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 12,
        },
      });
    });
  }, [waypoints]);
  const redirectToGoogleMaps = () => {
    const waypointsParam = waypoints
      .map((point: any) => `${point.lat},${point.lng}`)
      .join('|');
    const url = `https://www.google.com/maps/dir/?api=1&origin=${
      waypoints[0].lat
    },${waypoints[0].lng}&destination=${waypoints[waypoints.length - 1].lat},${
      waypoints[waypoints.length - 1].lng
    }&waypoints=${waypointsParam}&travelmode=driving`;
    window.open(url, '_blank');
  };
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog-centered min-w-lg-690px"
      className="border-r10px"
      centered
    >
      <Modal.Body className="pt-6">
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <div
            ref={mapRef}
            style={{
              width: '100%',
              paddingBottom: '100%', // Makes it square
              position: 'relative',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          </div>
          <button
            onClick={redirectToGoogleMaps}
            style={{
              marginTop: '20px',
              padding: '8px 16px',
              backgroundColor: '#2196F3',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            Open in Google Maps
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RouteMap;
