import React, { useEffect, useState } from 'react';
const GeocodeAddress = ({ lat, lng }: any) => {
  const [address, setAddress] = useState('Loading...');
  useEffect(() => {
    (async () => {
      await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAg41o-JrOGvOov4uDRb9CzL2AyfUMYDbI`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'OK') {
            const results = data.results;
            let address = '';
            let districtMatched = false;
            // Loop through the results to find the desired administrative levels
            for (const result of results) {
              if (result.types.includes('administrative_area_level_4')) {
                address = result.formatted_address; // or component.formatted_address if needed
                districtMatched = true;
                break;
              } else if (
                result.types.includes('establishment') ||
                result.types.includes('point_of_interest') ||
                result.types.includes('store')
              ) {
                // Level 4 found
                address = result.formatted_address; // or component.formatted_address if needed
                districtMatched = true;
                break;
              } else if (result.types.includes('administrative_area_level_3')) {
                // Level 3 found
                address = result.formatted_address; // or component.formatted_address if needed
                districtMatched = true;
                break;
              } else if (result.types.includes('administrative_area_level_2')) {
                // Level 2 found
                address = result.formatted_address; // or component.formatted_address if needed
                districtMatched = true;
                break;
              } else if (result.types.includes('administrative_area_level_1')) {
                // Level 1 found
                address = result.formatted_address; // or component.formatted_address if needed
                districtMatched = true;
                break;
              }
              if (districtMatched) {
                break; // Exit the outer loop if a match is found
              }
            }
            // If no specific administrative level was matched, you can set a default address
            if (!districtMatched) {
              address = 'No administrative area found';
            }
            console.log('Extracted Address:', address);
            setAddress(address); // Assuming setAddress is a function to set the address
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    })();
  }, [lat, lng]);
  return <span className="fs-15 fw-500">{address}</span>;
};
export default GeocodeAddress;
