import { Button, Card, Col, FormLabel, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
import afriCafeImg from '../../_admin/assets/media/product/nutro.png';
import { useEffect, useState } from 'react';
import { seller } from '../../api/apiEndPoints';
import APICallService from '../../api/apiCallService';
import Loader from '../../Global/loader';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../utils/constants';
import { error } from '../../Global/toast';
import Method from '../../utils/methods';
const SelectOfffer = (props: any) => {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [productData, setProductData] = useState<any>([]);
  const [validation, setValidation] = useState<{
    [key: string]: {
      isInvalid: boolean;
      remainingQty: number;
    };
  }>({});
  const [selectedVariantType, setSelectedVariantType] = useState<any>([]);
  const [variants, setVariants] = useState([]);
  const [option, setOption] = useState('');
  const [selectedOption, setSelectedOptions] = useState(
    JSON.parse(
      JSON.stringify(
        props.selectedVariant.map((val: any) => {
          return { ...val, available: true };
        })
      )
    )
  );
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchInfo(props.variantId);
      // console.log(selectedVariantType);
      setFetchLoader(false);
    })();
  }, [props.show]);
  const fetchInfo = async (variantId: string) => {
    let apiService = new APICallService(seller.sellableProductInfo, variantId,'','','','',props.moduleName);
    let response = await apiService.callAPI();
    let productObj = response;
    setOption(productObj.variants[0].variant._id);
    let definedVariantsTypes = productObj['definedVariantsTypes'] || [];
    let variantList = productObj['variants'] || [];
    let variantIdValueMap = variantList.reduce((p: any, c: any) => {
      c = c['variant'];
      p[getVariantTypeKey(c['variantType'])] = c;
      return p;
    }, {});
    setVariants(variantList);
    if (definedVariantsTypes.length === 0) {
      const { quantityTypes, dozensInCarton, piecesInCarton, piecesInDozen } =
        productObj.variants[0].variant;
      const existingProductIndex = props.selectedProduct.findIndex(
        (product: any) => product._id === productObj.variants[0].variant._id
      );
      if (existingProductIndex !== -1) {
        const existingProduct = props.selectedProduct[existingProductIndex];
        const quantityTypes = productObj.variants[0].variant.quantityTypes;
        quantityTypes.forEach((quantityType: any) => {
          const existingQuantityTypeIndex =
            existingProduct.quantityTypes.findIndex(
              (qt: any) => qt.type === quantityType.type
            );
          if (existingQuantityTypeIndex !== -1) {
            quantityType.stockCount =
              existingProduct.quantityTypes[
                existingQuantityTypeIndex
              ].stockCount;
          }
        });
      }
      let index =
        productObj.variants[0].variant.inventoryInfo.quantityTypes.findIndex(
          (sval: any) => sval.type === Dozen
        );
      let dozensInsideCarton =
        calculateRemainingQty(productObj.variants[0].variant, Dozen) +
        (calculateRemainingQty(productObj.variants[0].variant, CartonWithDozens)
          ? calculateRemainingQty(
              productObj.variants[0].variant,
              CartonWithDozens
            ) * dozensInCarton
          : 0);
      if (index == -1) {
        productObj.variants[0].variant.inventoryInfo.quantityTypes.push({
          stockCount: dozensInsideCarton,
          remainingQty: dozensInsideCarton,
          type: Dozen,
          reservedQty: 0,
        });
      } else {
        productObj.variants[0].variant.inventoryInfo.quantityTypes[index] = {
          ...productObj.variants[0].variant.inventoryInfo.quantityTypes[index],
          remainingQty: dozensInsideCarton,
        };
      }
      console.log(props.selectedProduct);
      setSelectedVariantType({
        ...productObj.variants[0].variant,
        business: response.business,
        category: response.category,
        subCategory: response.subCategory,
        groupCategory: response.groupCategory,
        taxFree: response.taxFree,
        productTax: response.productTax,
      });
    } else {
      // Display variant types and options
      let selectedKey = getVariantTypeKey(props.selectedVariant);
      let variantInfo = variantIdValueMap[selectedKey];
      if (variantInfo !== undefined) {
        const { quantityTypes, dozensInCarton, piecesInCarton, piecesInDozen } =
          variantInfo;
        const existingProductIndex = props.selectedProduct.findIndex(
          (product: any) => product._id === variantInfo._id
        );
        if (existingProductIndex !== -1) {
          const existingProduct = props.selectedProduct[existingProductIndex];
          const quantityTypes = variantInfo.quantityTypes;
          quantityTypes.forEach((quantityType: any) => {
            const existingQuantityTypeIndex =
              existingProduct.quantityTypes.findIndex(
                (qt: any) => qt.type === quantityType.type
              );
            if (existingQuantityTypeIndex !== -1) {
              quantityType.stockCount =
                existingProduct.quantityTypes[
                  existingQuantityTypeIndex
                ].stockCount;
            }
          });
        }
        let index = variantInfo.inventoryInfo.quantityTypes.findIndex(
          (sval: any) => sval.type === Dozen
        );
        console.log('index----', index);
        let dozensInsideCarton =
          calculateRemainingQty(variantInfo, Dozen) +
          (calculateRemainingQty(variantInfo, CartonWithDozens)
            ? calculateRemainingQty(variantInfo, CartonWithDozens) *
              dozensInCarton
            : 0);
        if (index == -1) {
          variantInfo.inventoryInfo.quantityTypes.push({
            stockCount: dozensInsideCarton,
            remainingQty: dozensInsideCarton,
            type: Dozen,
            reservedQty: 0,
          });
        } else {
          variantInfo.inventoryInfo.quantityTypes[index] = {
            ...variantInfo.inventoryInfo.quantityTypes[index],
            remainingQty: dozensInsideCarton,
          };
        }
        setSelectedVariantType({
          ...variantInfo,
          business: response.business,
          category: response.category,
          subCategory: response.subCategory,
          groupCategory: response.groupCategory,
          taxFree: response.taxFree,
          productTax: response.productTax,
        });
      } else {
      }
    }
    setProductData(response);
  };
  const handleVariantChange = (
    reference: string,
    option: string,
    index: number
  ) => {
    // let selectedKey = getVariantTypeKey(props.selectedVariant);
    let temp = [...selectedOption];
    temp[index] = { ...temp[index], option: option.trim() };
    let variantIdValueMap = variants.reduce((p: any, c: any) => {
      c = c['variant'];
      p[getVariantTypeKey(c['variantType'])] = c;
      return p;
    }, {});
    let variantInfo = variantIdValueMap[getVariantTypeKey(temp)];
    if (variantInfo !== undefined) {
      const existingProductIndex = props.selectedProduct.findIndex(
        (product: any) => product._id === variantInfo._id
      );
      if (existingProductIndex !== -1) {
        const existingProduct = props.selectedProduct[existingProductIndex];
        const quantityTypes = variantInfo.quantityTypes;
        quantityTypes.forEach((quantityType: any) => {
          const existingQuantityTypeIndex =
            existingProduct.quantityTypes.findIndex(
              (qt: any) => qt.type === quantityType.type
            );
          if (existingQuantityTypeIndex !== -1) {
            quantityType.stockCount =
              existingProduct.quantityTypes[
                existingQuantityTypeIndex
              ].stockCount;
          }
        });
      }
      temp[index] = { ...temp[index], option: option.trim(), available: true };
      setOption(variantInfo?._id);
      const { quantityTypes, dozensInCarton, piecesInCarton, piecesInDozen } =
        variantInfo;
      let index2 = variantInfo.inventoryInfo.quantityTypes.findIndex(
        (sval: any) => sval.type === Dozen
      );
      let dozensInsideCarton =
        calculateRemainingQty(variantInfo, Dozen) +
        (calculateRemainingQty(variantInfo, CartonWithDozens)
          ? calculateRemainingQty(variantInfo, CartonWithDozens) *
            dozensInCarton
          : 0);
      variantInfo.inventoryInfo.quantityTypes[index2] = {
        ...variantInfo.inventoryInfo.quantityTypes[index2],
        remainingQty: dozensInsideCarton,
      };
      setSelectedVariantType({
        ...variantInfo,
        business: productData.business,
        category: productData.category,
        subCategory: productData.subCategory,
        groupCategory: productData.groupCategory,
        taxFree: productData.taxFree,
        productTax: productData.productTax,
      });
    } else {
      temp[index] = { ...temp[index], option: option.trim(), available: false };
      error('Selected variant not available');
    }
    setSelectedOptions(temp);
  };
  function getVariantTypeKey(variantTypesList: any) {
    let refList = [];
    let optionList = [];
    for (let c of variantTypesList) {
      refList.push(c['reference'].trim());
      optionList.push(c['option'].trim());
    }
    refList.sort();
    optionList.sort();
    return [...refList, ...optionList].join('_').trim();
  }
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const handleAddProduct = () => {
    const temp = { ...selectedVariantType };
    const selectedProducts: any = [];
    temp.quantityTypes.forEach((quantityType: any) => {
      if (quantityType.stockCount > 0) {
        const selectedProductCopy = { ...temp };
        selectedProductCopy.quantityTypes = [quantityType];
        selectedProducts.push(selectedProductCopy);
      }
    });
    if (selectedProducts.length > 0) {
      props.onAddProduct(selectedProducts);
    } else {
      error('Quantity must be greater than 0');
    }
  };
  const calculateRemainingQty = (
    selectedVariantType: any,
    type: any
  ): number => {
    return (
      selectedVariantType.inventoryInfo?.quantityTypes.find(
        (sval: any) => sval.type === type
      )?.remainingQty ?? 0
    );
  };
  const isAddButtonDisabled = (
    selectedVariantType: any,
    quantVal: any
  ): boolean => {
    const { quantityTypes, dozensInCarton, piecesInCarton, piecesInDozen } =
      selectedVariantType;
    if (
      quantityTypes.some(
        (sval: any) => sval.stockCount > 0 && sval.type !== quantVal.type
      ) ||
      quantVal.type === Dozen
    ) {
      if (quantityTypes.some((sval: any) => sval.type === CartonWithDozens)) {
        return (
          calculateRemainingQty(selectedVariantType, quantVal.type) +
            calculateRemainingQty(selectedVariantType, CartonWithDozens) *
              dozensInCarton <=
          quantVal.stockCount
        );
      }
      return false;
    }
    if (quantVal.type === Piece) {
      if (
        quantityTypes.some(
          (sval: any) => sval.type === CartonWithPieces || sval.type === Dozen
        )
      ) {
        return (
          calculateRemainingQty(selectedVariantType, quantVal.type) +
            (calculateRemainingQty(selectedVariantType, Dozen)
              ? calculateRemainingQty(selectedVariantType, Dozen) *
                piecesInDozen
              : 0) +
            (calculateRemainingQty(selectedVariantType, CartonWithPieces)
              ? calculateRemainingQty(selectedVariantType, CartonWithPieces) *
                piecesInCarton
              : 0) <=
          quantVal.stockCount
        );
      }
      return false;
    }
    return (
      calculateRemainingQty(selectedVariantType, quantVal.type) <=
      quantVal.stockCount
    );
  };
  console.log('selectedVariantType', selectedVariantType)
  const handleQuantityChange = (value: any, index: number, type: any) => {
    value = parseInt(Method.trimLeadingZeros(value));
    const validationKey = `${type}-${option}`;
    const item = selectedVariantType.inventoryInfo?.quantityTypes.find(
      (item: any) => item.type === type
    );
    let temp = { ...selectedVariantType };
    const quantityTypeIndex = temp.quantityTypes.findIndex(
      (item: any) => item.type === type
    );
    const { quantityTypes, dozensInCarton, piecesInCarton, piecesInDozen } =
      selectedVariantType;
    if (type === Dozen) {
      if (
        selectedVariantType.inventoryInfo.quantityTypes.some(
          (sval: any) => sval.type === CartonWithDozens
        ) ||
        type === Dozen
      ) {
        if (
          calculateRemainingQty(selectedVariantType, type) +
            calculateRemainingQty(selectedVariantType, CartonWithDozens) *
              dozensInCarton >=
          value
        ) {
          if (quantityTypeIndex !== -1) {
            temp.quantityTypes[quantityTypeIndex]['stockCount'] = value;
          }
        } else {
          if (quantityTypeIndex !== -1) {
            temp.quantityTypes[quantityTypeIndex]['stockCount'] =
              calculateRemainingQty(selectedVariantType, type);
          }
        }
        const remainingQty =
          calculateRemainingQty(selectedVariantType, type) || 0;
        const newValidation: any = {
          [validationKey]: {
            isInvalid: value > remainingQty,
            remainingQty: remainingQty,
          },
        };
        setValidation(newValidation);
      }
    } else if (type === Piece) {
      if (
        selectedVariantType.inventoryInfo.quantityTypes.some(
          (sval: any) => sval.type === CartonWithPieces || sval.type === Dozen
        )
      ) {
        if (
          calculateRemainingQty(selectedVariantType, type) +
            (calculateRemainingQty(selectedVariantType, Dozen)
              ? calculateRemainingQty(selectedVariantType, Dozen) *
                piecesInDozen
              : 0) +
            (calculateRemainingQty(selectedVariantType, CartonWithPieces)
              ? calculateRemainingQty(selectedVariantType, CartonWithPieces) *
                piecesInCarton
              : 0) >=
          value
        ) {
          if (quantityTypeIndex !== -1) {
            temp.quantityTypes[quantityTypeIndex]['stockCount'] = value;
          }
        } else {
          if (quantityTypeIndex !== -1) {
            temp.quantityTypes[quantityTypeIndex]['stockCount'] =
              calculateRemainingQty(selectedVariantType, type) +
              (calculateRemainingQty(selectedVariantType, Dozen)
                ? calculateRemainingQty(selectedVariantType, Dozen) *
                  piecesInDozen
                : 0) +
              (calculateRemainingQty(selectedVariantType, CartonWithPieces)
                ? calculateRemainingQty(selectedVariantType, CartonWithPieces) *
                  piecesInCarton
                : 0);
          }
        }
        const remainingQty =
          calculateRemainingQty(selectedVariantType, type) +
            (calculateRemainingQty(selectedVariantType, Dozen)
              ? calculateRemainingQty(selectedVariantType, Dozen) *
                piecesInDozen
              : 0) +
            (calculateRemainingQty(selectedVariantType, CartonWithPieces)
              ? calculateRemainingQty(selectedVariantType, CartonWithPieces) *
                piecesInCarton
              : 0) || 0;
        const newValidation: any = {
          [validationKey]: {
            isInvalid: value > remainingQty,
            remainingQty: remainingQty,
          },
        };
        setValidation(newValidation);
      }
    } else {
      if (quantityTypeIndex !== -1) {
        if (value > item?.remainingQty || !item?.remainingQty)
          temp.quantityTypes[quantityTypeIndex]['stockCount'] =
            item?.remainingQty;
        else temp.quantityTypes[quantityTypeIndex]['stockCount'] = value;
        console.log(
          value > item?.remainingQty,
          item?.remainingQty,
          temp.quantityTypes[quantityTypeIndex]['stockCount']
        );
        const remainingQty = item?.remainingQty || 0;
        const newValidation: any = {
          [validationKey]: {
            isInvalid: value > remainingQty,
            remainingQty: remainingQty,
          },
        };
        setValidation(newValidation);
      }
    }
    temp.quantityTypes = temp.quantityTypes.map((item: any, index: number) => {
      if (index != quantityTypeIndex) {
        item.stockCount = 0;
      }
      return item;
    });
    setSelectedVariantType(temp);
  };
  function getVariantUnitAndTotalPriceByQuantityType(
    quantityTypeObj: any,
    quantity: any = 0
  ) {
    let unitPrice = quantityTypeObj['amount'] || 0;
    let originalPrice = quantityTypeObj['amount'] || 0;
    if (quantityTypeObj['discountAmtEnabled']) {
      unitPrice = quantityTypeObj['discountAmt'] || 0;
    } else if (quantityTypeObj['discountByQuantitiesEnabled']) {
      let availableDiscountRanges =
        quantityTypeObj['discountsByQuantities'] || [];
      if (availableDiscountRanges.length > 0) {
        availableDiscountRanges.sort((a: any, b: any) => a['min'] - b['min']);
        if (quantity < availableDiscountRanges[0]['min']) {
          //consider original price as it is
        } else {
          let { matchedRange, maxRange } = availableDiscountRanges.reduce(
            (p: any, c: any) => {
              if (quantity >= c['min'] && quantity <= c['max']) {
                p.matchedRange = c;
              }
              if (quantity > c['min']) {
                p.maxRange = c;
              }
              return p;
            },
            {
              matchedRange: undefined,
              maxRange: undefined,
            }
          );
          if (matchedRange) {
            unitPrice = matchedRange['discountAmt'] || 0;
          } else {
            unitPrice = maxRange['discountAmt'] || 0;
          }
        }
      } else {
        //consider original price as it is
      }
    }
    return {
      originalPrice,
      unitPrice,
      totalPrice: quantity * unitPrice,
    };
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-1000px min-w-md-750px"
        className="border-r10px"
      >
        <div className="close-inner-top-2 m-0">
          <img
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
        </div>
        {fetchLoader ? (
          <>
            {' '}
            <Modal.Body className="">
              <Row className="g-5 h-250px">
                <div className="d-flex align-items-center justify-content-center">
                  <Loader loading={fetchLoader} />
                </div>
              </Row>
            </Modal.Body>
          </>
        ) : (
          <>
            {/*     Object.keys(selectedVariantType).length  */}
            {productData &&
            Object.keys(productData).length &&
            selectedVariantType &&
            Object.keys(selectedVariantType).length ? (
              <>
                {/* {productData.definedVariantsTypes.length ? (
                ) : (
                  <></>
                )} */}
                <>
                  <Modal.Header className="border-bottom-0 pb-2 mx-auto">
                    <Row className="text-center">
                      <Col xs={12}>
                        <div
                          id="kt_carousel_1_carousel"
                          className="carousel carousel-custom text-center"
                          data-bs-ride="carousel"
                          data-bs-interval="5000"
                        >
                          <div className="carousel-inner">
                            {selectedVariantType &&
                              selectedVariantType.media.map(
                                (media: any, index: number) => (
                                  <div
                                    className={`carousel-item ${
                                      index === 0 ? 'active' : ''
                                    }`}
                                    key={media._id}
                                  >
                                    <div className="symbol border symbol-279px">
                                      <img
                                        className="img-fluid object-fit-contain"
                                        src={media.url} // Assuming 'url' contains the media URL for the variant
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                            {selectedVariantType &&
                              selectedVariantType.media.map(
                                (media: any, index: number) => (
                                  <li
                                    key={media._id}
                                    data-bs-target="#kt_carousel_1_carousel"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                  ></li>
                                )
                              )}
                          </ol>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <span className="fs-26 fw-bolder">
                          {selectedVariantType.title.replace(/\s*\)\s*/g, ')')}
                        </span>
                        <h3 className="fs-26 fw-bolder"></h3>
                        <span className="fs-18 fw-500 text-gray">
                          Brand: {productData.brand.title}
                        </span>
                        {!productData.taxFree && productData.productTax ? (
                          <>
                            <h3 className="fs-26 fw-bolder"></h3>
                            <span className="fs-18 fw-500 text-gray">
                              Tax: {productData.productTax}%
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Modal.Header>
                  <div className="separator"></div>
                  {productData.definedVariantsTypes &&
                  productData.definedVariantsTypes.length ? (
                    <>
                      <Modal.Body>
                        <Row className="g-5">
                          {productData.definedVariantsTypes.map(
                            (variantVal: any, index: number) => {
                              return (
                                <Col
                                  md={
                                    productData.definedVariantsTypes.length ===
                                    1
                                      ? 12
                                      : 6
                                  }
                                >
                                  <FormLabel className="fs-18 fw-500">
                                    {variantVal.title}:
                                  </FormLabel>
                                  <div className="d-flex flex-row flex-wrap gap-3">
                                    {variantVal.options.map(
                                      (optionVal: any) => {
                                        const isActive =
                                          selectedOption[
                                            index
                                          ].option.trim() === optionVal.trim();
                                        return (
                                          <Button
                                            variant="link"
                                            className={`badge badge-secondary border-r23px fs-16 fw-600 btn-active-primary ${
                                              isActive
                                                ? selectedOption[index]
                                                    .available
                                                  ? 'active'
                                                  : ''
                                                : ''
                                            }`}
                                            onClick={(event) => {
                                              handleVariantChange(
                                                variantVal.reference,
                                                optionVal.trim(),
                                                index
                                              );
                                            }}
                                            // disabled={!isActive} // Disable the button if already selected
                                            key={optionVal}
                                          >
                                            <span className="px-4">
                                              {optionVal}
                                            </span>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </div>
                                </Col>
                              );
                            }
                          )}{' '}
                        </Row>
                      </Modal.Body>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {productData.definedVariantsTypes &&
                  productData.definedVariantsTypes.length ? (
                    <div className="separator"></div>
                  ) : (
                    <></>
                  )}{' '}
                  {selectedVariantType.quantityTypes &&
                  selectedVariantType.quantityTypes.length ? (
                    <>
                      {selectedVariantType.quantityTypes.reduce(
                        (acc: any, product: any) => {
                          return (
                            acc +
                            (product.discountByQuantitiesEnabled
                              ? product.discountsByQuantities.length
                              : 0)
                          );
                        },
                        0
                      ) ? (
                        <Modal.Body className="mt-0">
                          <FormLabel className="fs-18 fw-500">
                            {/* {quantVal.discountsByQuantities.length} Offers
                          available{' '}
                          {quantVal.type === CartonWithPieces ? 'Carton' : ''}
                          {quantVal.type === Dozen ? 'Dozen' : ''}
                          {quantVal.type === Piece ? 'Piece' : ''}: */}
                            {selectedVariantType.quantityTypes.reduce(
                              (acc: any, product: any) => {
                                return (
                                  acc +
                                  (product.discountByQuantitiesEnabled
                                    ? product.discountsByQuantities.length
                                    : 0)
                                );
                              },
                              0
                            )}{' '}
                            Offers available
                          </FormLabel>
                          <Card>
                            <Card.Body className="overflow-x-scroll p-4">
                              <div className="d-flex flex-row gap-3">
                                {selectedVariantType.quantityTypes.map(
                                  (quantVal: any, quantIndex: number) => {
                                    return (
                                      <>
                                        {quantVal.discountsByQuantities &&
                                        quantVal.discountsByQuantities
                                          .length ? (
                                          <>
                                            {quantVal.discountsByQuantities.map(
                                              (val: any) => {
                                                return (
                                                  <div className="border border-r6px bg-f9f9f9 p-4 min-w-lg-141px min-w-125px">
                                                    <div className="d-flex flex-column fs-16 fw-500 pb-1">
                                                      <span>
                                                        Buy {val.min} -{' '}
                                                        {val.max}
                                                      </span>
                                                      <span>
                                                        {' '}
                                                        {quantVal.type ===
                                                        CartonWithPieces
                                                          ? 'Carton'
                                                          : ''}
                                                        {quantVal.type === Dozen
                                                          ? 'Dozen'
                                                          : ''}
                                                        {quantVal.type === Piece
                                                          ? 'Piece'
                                                          : ''}
                                                        : in just
                                                      </span>
                                                    </div>
                                                    <span className="fs-18 fw-bolder">
                                                      TSh{' '}
                                                      {Method.getGeneralizedAmount(
                                                        val.discountAmt
                                                      )}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  }
                                )}{' '}
                              </div>
                            </Card.Body>
                          </Card>
                        </Modal.Body>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="separator"></div>
                  <Modal.Body>
                    <FormLabel className="fs-18 fw-500">
                      Select quantity
                      {/* (Coffee / 100g) */}
                    </FormLabel>
                    <Row className="g-5">
                      {selectedVariantType.quantityTypes.map(
                        (quantVal: any, quantIndex: number) => {
                          const validationKey = `${quantVal.type}-${option}`;
                          return (
                            <Col md={6}>
                              <div className="bg-f9f9f9 border border-r8px p-6">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex flex-column">
                                    <span className="fs-16 fw-500">
                                      1{' '}
                                      {quantVal.type === CartonWithDozens ||
                                      quantVal.type === CartonWithPieces
                                        ? 'Carton'
                                        : ''}
                                      {quantVal.type === Dozen ? 'Dozen' : ''}
                                      {quantVal.type === Piece ? 'Piece' : ''}
                                    </span>
                                    <span className="fs-16 fw-600">
                                      {/* {quantVal.discountAmtEnabled ? (
                                        <>
                                          {'TSh ' + quantVal.discountAmt}{' '}
                                          <span className="text-decoration-line-through text-gray ms-1">
                                            TSh {quantVal.amount}
                                          </span>
                                        </>
                                      ) : (
                                        'TSh ' + quantVal.amount
                                      )} */}
                                      {quantVal.discountAmtEnabled ? (
                                        <>
                                          {'TSh ' +
                                            Method.getGeneralizedAmount(
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                quantVal,
                                                quantVal.stockCount
                                              ).unitPrice
                                            )}{' '}
                                          <span className="text-decoration-line-through text-gray ms-1">
                                            TSh{' '}
                                            {Method.getGeneralizedAmount(
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                quantVal,
                                                quantVal.stockCount
                                              ).originalPrice
                                            )}
                                          </span>
                                        </>
                                      ) : quantVal.discountByQuantitiesEnabled ? (
                                        <>
                                          {getVariantUnitAndTotalPriceByQuantityType(
                                            quantVal,
                                            quantVal.stockCount
                                          ).unitPrice !==
                                          getVariantUnitAndTotalPriceByQuantityType(
                                            quantVal,
                                            quantVal.stockCount
                                          ).originalPrice ? (
                                            <>
                                              {'TSh ' +
                                                Method.getGeneralizedAmount(
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    quantVal,
                                                    quantVal.stockCount
                                                  ).unitPrice
                                                )}{' '}
                                              <span className="text-decoration-line-through text-gray ms-1">
                                                TSh{' '}
                                                {Method.getGeneralizedAmount(
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    quantVal,
                                                    quantVal.stockCount
                                                  ).originalPrice
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            'TSh ' +
                                            Method.getGeneralizedAmount(
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                quantVal,
                                                quantVal.stockCount
                                              ).unitPrice
                                            )
                                          )}
                                        </>
                                      ) : (
                                        'TSh ' +
                                        Method.getGeneralizedAmount(
                                          getVariantUnitAndTotalPriceByQuantityType(
                                            quantVal,
                                            quantVal.stockCount
                                          ).unitPrice
                                        )
                                      )}
                                    </span>
                                    {quantVal.stockCount ? (
                                      <span className="fs-16 fw-600">
                                        {quantVal.discountAmtEnabled ? (
                                          <>
                                            {'TSh ' +
                                              Method.getGeneralizedAmount(
                                                quantVal.stockCount *
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    quantVal,
                                                    quantVal.stockCount
                                                  ).unitPrice
                                              )}{' '}
                                            <span className="text-decoration-line-through text-gray ms-1">
                                              TSh{' '}
                                              {Method.getGeneralizedAmount(
                                                getVariantUnitAndTotalPriceByQuantityType(
                                                  quantVal,
                                                  quantVal.stockCount
                                                ).originalPrice
                                              )}
                                            </span>
                                          </>
                                        ) : quantVal.discountByQuantitiesEnabled ? (
                                          <>
                                            {getVariantUnitAndTotalPriceByQuantityType(
                                              quantVal,
                                              quantVal.stockCount
                                            ).unitPrice !==
                                            getVariantUnitAndTotalPriceByQuantityType(
                                              quantVal,
                                              quantVal.stockCount
                                            ).originalPrice ? (
                                              <>
                                                {'TSh ' +
                                                  Method.getGeneralizedAmount(
                                                    quantVal.stockCount *
                                                      getVariantUnitAndTotalPriceByQuantityType(
                                                        quantVal,
                                                        quantVal.stockCount
                                                      ).unitPrice
                                                  )}{' '}
                                                <span className="text-decoration-line-through text-gray ms-1">
                                                  TSh{' '}
                                                  {Method.getGeneralizedAmount(
                                                    getVariantUnitAndTotalPriceByQuantityType(
                                                      quantVal,
                                                      quantVal.stockCount
                                                    ).originalPrice
                                                  )}
                                                </span>
                                              </>
                                            ) : (
                                              'TSh ' +
                                              Method.getGeneralizedAmount(
                                                quantVal.stockCount *
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    quantVal,
                                                    quantVal.stockCount
                                                  ).unitPrice
                                              )
                                            )}
                                          </>
                                        ) : (
                                          'TSh ' +
                                          Method.getGeneralizedAmount(
                                            quantVal.stockCount *
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                quantVal,
                                                quantVal.stockCount
                                              ).unitPrice
                                          )
                                        )}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="stepperInput">
                                    <Button
                                      size="sm"
                                      className="button button--addOnLeft"
                                      onClick={() => {
                                        const currentStockCount = parseInt(
                                          quantVal.stockCount !== undefined
                                            ? quantVal.stockCount
                                            : 0
                                        );
                                        if (currentStockCount > 0) {
                                          handleQuantityChange(
                                            currentStockCount - 1,
                                            quantIndex,
                                            quantVal.type
                                          );
                                        }
                                      }}
                                      disabled={quantVal.stockCount <= 0}
                                    >
                                      -
                                    </Button>
                                    <input
                                      type="text"
                                      value={
                                        quantVal.stockCount !== undefined
                                          ? quantVal.stockCount
                                          : 0
                                      }
                                      className="input stepperInput__input form-control"
                                      onChange={(event: any) => {
                                        handleQuantityChange(
                                          event.target.value,
                                          quantIndex,
                                          quantVal.type
                                        );
                                      }}
                                      onKeyPress={(event: any) => {
                                        handleOnKeyPress(event);
                                      }}
                                    />
                                    <Button
                                      size="sm"
                                      className="button button--addOnRight"
                                      onClick={() => {
                                        handleQuantityChange(
                                          parseInt(
                                            quantVal.stockCount !== undefined
                                              ? quantVal.stockCount
                                              : 0
                                          ) + 1,
                                          quantIndex,
                                          quantVal.type
                                        );
                                      }}
                                      disabled={isAddButtonDisabled(
                                        selectedVariantType,
                                        quantVal
                                      )}
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                                {validation[validationKey]?.isInvalid ? (
                                  <label
                                    htmlFor=""
                                    className="fs-14 fw-500 mb-3 text-danger"
                                  >
                                    You can add up to{' '}
                                    {validation[validationKey].remainingQty}{' '}
                                    products
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </Modal.Body>
                </>
                <Modal.Footer className="mx-auto pt-0 pb-8 mb-4 border-top-0">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      handleAddProduct();
                    }}
                    // disabled={Object.values(validation).some(
                    //   (v) => v.isInvalid === true
                    // )}
                  >
                    {!props.loading && (
                      <span className="indicator-label fs-16 fw-bold">
                        Add product
                      </span>
                    )}
                    {props.loading && (
                      <span
                        className="indicator-progress fs-16 fw-bold"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
export default SelectOfffer;
